@import "https://fonts.googleapis.com/css2?family=GFS+Didot&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
body {
  font-family: Montserrat, sans-serif;
}

h1 {
  font-family: GFS Didot, serif;
}

p {
  text-align: center;
}

.img-container {
  height: 90%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.center {
  width: 50%;
}

/*# sourceMappingURL=index.fe969d4c.css.map */
